import React, { PureComponent } from 'react';
import { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
//import { cambiarContrasena } from '../../../services/nuevaContraseña.service';
import './styles.css'
import { LoadingMovilGo } from '../../../shared/loadingMovilGo';
import { numResolucionBono2024_1, numResolucionBonoMillo, numResolucionBYD, numResolucionTaxi, numResolucionTaxi2024_1 } from '../../../utilities/odoo-config';



const DesktopView = (props) => {
    const { images, loading, datosRifa, numResolucion } = props
    var imageHorizontal = []
    //console.log("data en DesktopView", images, datosRifa, numResolucion);
    if (numResolucion == numResolucionBYD){
        imageHorizontal = images.slice(0, 2)
    }

    const [currentImageIndex, SetCurrentImageIndex] = useState(0);
    const [isFlipped, SetIsFlipped] = useState(false);

    const handleImageChange = (index) => {
        SetIsFlipped(true);
        setTimeout(() => {
            SetCurrentImageIndex(index);
            SetIsFlipped(false);
        }, 200); // Ajusta el tiempo de acuerdo a la duración de tu animación CSS
    };

    const handleNextImage = () => {
        if (numResolucion == numResolucionBYD) {
            const nextIndex = (currentImageIndex + 1) % imageHorizontal.length;
            handleImageChange(nextIndex);
        } else {
            const nextIndex = (currentImageIndex + 1) % images.length;
            handleImageChange(nextIndex);
        }
    };

    const handlePreviousImage = () => {
        if (numResolucion == numResolucionBYD) {
            const previousIndex = (currentImageIndex - 1 + imageHorizontal.length) % imageHorizontal.length;
            handleImageChange(previousIndex);
        } else {
            const previousIndex = (currentImageIndex - 1 + images.length) % images.length;
            handleImageChange(previousIndex);
        }
    };

    if (numResolucion === numResolucionBonoMillo ) {
        return (
            <div style={{ height: '62vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                <div style={{ height: '100%', width: '100%', maxWidth: '805px', margin: '-3.5% auto 50px', position: 'relative' }}>
                    <div className='page-flip'>
                        <div style={{ marginTop: '7.5%', height: '98%', width: '99%', position: 'relative', borderRadius: '10px', boxShadow: '10px 5px 8px 0px rgba(0, 0, 0, 0.5), -10px 5px 8px 0px rgba(0, 0, 0, 0.5)' }}>
                            {images.map((imageUrl, index) => (
                                <div
                                    key={index}
                                    className={`page ${index === currentImageIndex ? 'page-front' : 'page-back'} ${isFlipped ? 'is-flipped' : ''}`}
                                >
                                    <img style={{width:'100%', height: '100%', borderRadius: '10px' }} src={imageUrl.trim()} alt={`Imagen ${index + 1}`} />
                                </div>
                            ))}
                            {currentImageIndex + 1 === 1 ? ( //valida la primera pagina
                                <>
                                    <h6 style={{
                                        color: 'red',
                                        fontWeight: 'bold',
                                        position: 'absolute',
                                        transform: 'translate(-50%, -50%)',
                                        zIndex: '3',
                                        top: '93%', left: '320px', 
                                    }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero : 'No hay datos'}
                                    </h6>
                                    <h6 style={{
                                        color: 'red',
                                        fontWeight: 'bold',
                                        position: 'absolute',
                                        transform: 'translate(-50%, -50%)',
                                        zIndex: '3',
                                        top: '93%', left: '470px',
                                    }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero_promocional : 'No hay datos'}
                                    </h6>
                                </>
                            ) : ''}

                            {images.length - 1 === currentImageIndex ? ( //valida la ultima pagina
                                <>
                                    <h6 style={{
                                        color: 'red',
                                        fontWeight: 'bold',
                                        position: 'absolute',
                                        transform: 'translate(-50%, -50%)',
                                        zIndex: '3',
                                        top: '78%', left: '160px',
                                    }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero : 'No hay datos'}
                                    </h6>
                                    <h6 style={{
                                        color: 'red',
                                        fontWeight: 'bold',
                                        position: 'absolute',                                        
                                        transform: 'translate(-50%, -50%)',
                                        zIndex: '3',
                                        top: '78%', left: '300px',
                                    }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero_promocional : 'No hay datos'}
                                    </h6>
                                </>
                            ) : ''}

                            {images.length - 2 === currentImageIndex ? ( //valida la penultima pagina
                                <>
                                    <p style={{ color: 'black', position: 'absolute', top: '11%', right: '300px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '12px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].nombre : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '16.5%', right: '300px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '12px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].apellido : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '22%', right: '300px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '12px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].CC : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '28%', right: '220px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '12px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].direcion : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '34%', right: '270px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '12px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].barrio : 'No hay datos'}</p>

                                    {/* <p style={{ color: 'black', position: 'absolute', top: '42%', right: '300px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '12px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].telefono : 'No hay datos'}</p> */}

                                    <p style={{ color: 'black', position: 'absolute', top: '40%', right: '300px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '12px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].celular : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '45%', right: '250px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '12px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].email : 'No hay datos'}</p>

                                </>
                            ) : ''}


                        </div>

                    </div>

                </div>

                <div className="botones-container" style={{ top: '51%', right: '50%' }}>
                    <button disabled={loading} onClick={handlePreviousImage} className="boton-anterior" >
                        Anterior
                    </button>
                    <p className="paginado" style={{ fontWeight: 'bold' }} >{currentImageIndex + 1}/{images.length}</p>

                    <button disabled={loading} onClick={handleNextImage} className="boton-siguiente">
                        Siguiente
                    </button>
                </div>
            </div>
        )
    } else if (numResolucion === numResolucionTaxi ) {
        return (
            <div style={{ height: '62vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                <div style={{ height: '100%', width: '100%', maxWidth: '797px', margin: '-3.5% auto 50px', position: 'relative' }}>
                    <div className='page-flip'>
                        <div style={{ marginTop: '7.5%', height: '98%', width: '100%', position: 'relative', borderRadius: '10px', boxShadow: '10px 5px 8px 0px rgba(0, 0, 0, 0.5), -10px 5px 8px 0px rgba(0, 0, 0, 0.5)' }}>
                            {images.map((imageUrl, index) => (
                                <div
                                    key={index}
                                    className={`page ${index === currentImageIndex ? 'page-front' : 'page-back'} ${isFlipped ? 'is-flipped' : ''}`}
                                >
                                    <img style={{ width:'100%', height: '100%', borderRadius: '10px' }} src={imageUrl.trim()} alt={`Imagen ${index + 1}`} />
                                </div>
                            ))}
                            {currentImageIndex + 1 === 1 ? ( //valida la primera pagina
                                <>
                                    <h6 style={{ color: 'red', fontWeight: 'bold', fontSize: '40px', position: 'absolute', top: '43%', left: '110px', transform: 'translate(-50%, -50%)', zIndex: '3' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero : 'No hay datos'}
                                    </h6>
                                    <h6 style={{ color: 'red', fontWeight: 'bold', fontSize: '40px', position: 'absolute', top: '43%', left: '280px', transform: 'translate(-50%, -50%)', zIndex: '3' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero_promocional : 'No hay datos'}
                                    </h6>
                                </>
                            ) : ''}

                            {images.length - 1 === currentImageIndex ? ( //valida la ultima pagina
                                <>
                                    <h6 style={{ color: 'red', fontWeight: 'bold', fontSize: '40px', position: 'absolute', top: '43%', left: '210px', transform: 'translate(-50%, -50%)', zIndex: '3' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero : 'No hay datos'}
                                    </h6>
                                    <h6 style={{ color: 'red', fontWeight: 'bold', fontSize: '40px', position: 'absolute', top: '61%', left: '210px', transform: 'translate(-50%, -50%)', zIndex: '3' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero_promocional : 'No hay datos'}
                                    </h6>
                                </>
                            ) : ''}

                            {images.length - 2 === currentImageIndex ? ( //valida la penultima pagina
                                <>
                                    <p style={{ color: 'black', position: 'absolute', top: '14%', right: '300px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '20px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].nombre : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '22%', right: '300px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '20px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].apellido : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '30%', right: '300px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '20px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].CC : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '38%', right: '300px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '20px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].telefono : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '45%', right: '300px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '20px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].celular : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '53%', right: '250px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '20px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].email : 'No hay datos'}</p>

                                </>
                            ) : ''}


                        </div>

                    </div>

                </div>

                <div className="botones-container" style={{ top: '51%', right: '50%' }}>
                    <button disabled={loading} onClick={handlePreviousImage} className="boton-anterior" >
                        Anterior
                    </button>
                    <p className="paginado" style={{ fontWeight: 'bold' }} >{currentImageIndex + 1}/{images.length}</p>

                    <button disabled={loading} onClick={handleNextImage} className="boton-siguiente">
                        Siguiente
                    </button>
                </div>
            </div>
        )
    } else if (numResolucion === numResolucionBYD) {
        return (
            <div style={{ height: '62vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                <div style={{ height: '100%', width: '100%', maxWidth: '795px', margin: '-3.5% auto 50px', position: 'relative' }}>
                    <div className='page-flip'>
                        <div style={{ marginTop: '7.5%', height: '98%', width: '100%', position: 'relative', borderRadius: '10px', boxShadow: '10px 5px 8px 0px rgba(0, 0, 0, 0.5), -10px 5px 8px 0px rgba(0, 0, 0, 0.5)' }}>
                            {imageHorizontal.map((imageUrl, index) => (
                                <div
                                    key={index}
                                    className={`page ${index === currentImageIndex ? 'page-front' : 'page-back'} ${isFlipped ? 'is-flipped' : ''}`}
                                >
                                    <img style={{ width:'100%', height: '100%', borderRadius: '10px' }} src={imageUrl.trim()} alt={`Imagen ${index + 1}`} />
                                </div>
                            ))}
                            {currentImageIndex + 1 === 1 ? ( //valida la primera pagina
                                <>
                                    <h6 style={{ color: 'red', fontWeight: 'bold', fontSize: '18px', position: 'absolute', top: '15%', left: '40px', transform: 'translate(-50%, -50%)', zIndex: '3' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero : 'No hay datos'}
                                    </h6>
                                </>
                            ) : ''}

                            {imageHorizontal.length - 1 === currentImageIndex ? ( //valida la ultima pagina
                                <>
                                    <h6 style={{ color: 'red', fontWeight: 'bold', fontSize: '30px', position: 'absolute', top: '50%', left: '690px', transform: 'translate(-50%, -50%)', zIndex: '3' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero : 'No hay datos'}
                                    </h6>

                                    <p style={{ color: 'black', width:'250px', position: 'absolute', top: '13%', left: '490px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '15px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].nombre + " " +datosRifa[0].apellido: 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '21%', left: '540px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '15px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].CC : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '29%', left: '530px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '15px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].celular : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '36.5%', left: '440px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '15px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].email : 'No hay datos'}</p>
                                </>
                            ) : ''}


                        </div>

                    </div>

                </div>

                <div className="botones-container" style={{ top: '51%', right: '50%' }}>
                    <button disabled={loading} onClick={handlePreviousImage} className="boton-anterior" >
                        Anterior
                    </button>
                    <p className="paginado" style={{ fontWeight: 'bold' }} >{currentImageIndex + 1}/{imageHorizontal.length}</p>

                    <button disabled={loading} onClick={handleNextImage} className="boton-siguiente">
                        Siguiente
                    </button>
                </div>

            </div>
        )
    }


}

export default DesktopView