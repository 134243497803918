import Odoo from 'odoo-xmlrpc';
import { OdooConfig } from '../utilities/odoo-config';
import { Desencrypt } from '../utilities/cipher';

export const payMenPSE = async (object,user, callback) => {
    const config = await OdooConfig();
    const data = await Desencrypt();
    const args = [[
        0, object
    ]]
    const params = {
        model: "payu.webservice",
        method: "Consumir_PayU_pse",
    }
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: user.user,
        password: user.password
    });
    console.log("cliente concet del payMenPSE",client)
    client.connect((err, response) => {
        if (err) {        
            return callback(false,err )
        } else {
            client.execute_kw(params.model, params.method, args, (err, response) => {
                if (err) {     
                    console.log("Falló", err)               
                    return callback(false,err);
                }else{
                    console.log("bien", response)
                    return callback( true,response);
                }
                
            });
        }

    })
}
export const payMenPSE2 = async (object, callback) => {
    const config = await OdooConfig();
    const data = await Desencrypt();  
    const args = [[
        0, object
    ]]
    const params = {
        model: "movilgo.webservice",
        method: "transaccionPayu",
    }
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });
    client.connect((err, response) => {
        if (err) {        
            return callback(false,err )
        } else {
            client.execute_kw(params.model, params.method, args, (err, response) => {
                if (err) {                    
                    return callback(false,err);
                }else{
                    return callback( true,response);
                }
                
            });
        }

    })
}
export const getBanks = async (user,callback) => {
    const config = await OdooConfig();
    const data = await Desencrypt();
    const args = [[0]]
    const params = {
        model: "payu.webservice",
        method: "get_lista_bancos",
    }
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: user.user,
        password: user.password
    });
    client.connect((err, response) => {
        if (err) {        
            return callback(false,err )
        } else {
            //console.log("conecto",client)
            client.execute_kw(params.model, params.method, args, (err, response) => {
                if (err) {                    
                    return callback(false,err);
                }else{
                    return callback( true,response);
                }
                
            });
        }

    })
}

export const getCities = async (user,callback) => {
    const config = await OdooConfig();
    const data = await Desencrypt();
    const args = [[0]]
    const params = {
        model: "movilgo.webservice",
        method: "get_lista_cuidades_departamentos",
    }
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: user.user,
        password: user.password
    });
    client.connect((err, response) => {
        if (err) {        
            return callback(false,err )
        } else {
            client.execute_kw(params.model, params.method, args, (err, response) => {
                if (err) {                    
                    return callback(false,err);
                }else{
                    return callback( true,response);
                }
                
            });
        }

    })
}

export const payMenCARD = async (object,user,callback) => {
    const config = await OdooConfig();
    const data = await Desencrypt();
    const args = [[0,object]]
    const params = {
        model: "movilgo.webservice",
        method: "consumirPagoTarjeta",
    }
    //get_lista_cuidades_departamentos
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: user.user,
        password: user.password
    });
    console.log("cliente conect del payMenCARD",client)
    client.connect((err, response) => {
        if (err) {        
            return callback(false,err )
        } else {
            client.execute_kw(params.model, params.method, args, (err, response) => {
                if (err) {                    
                    return callback(false,err);
                }else{
                    return callback( true,response);
                }
                
            });
        }

    })
}

export const payMenRefacil = async (object,user,callback) => {
    const config = await OdooConfig();
    const data = await Desencrypt();
    const args = [[0,object]]
    const params = {
        model: "movilgo.formapago",
        method: "consumir_pagoEfectivo",
    }
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: user.user,
        password: user.password
    });
    console.log("cliente conect del consumir_pagoEfectivo",client)
    client.connect((err, response) => {
        if (err) {        
            return callback(false,err )
        } else {
            client.execute_kw(params.model, params.method, args, (err, response) => {
                if (err) {                    
                    return callback(false,err);
                }else{
                    return callback( true,response);
                }
                
            });
        }

    })
}