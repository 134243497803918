import React, { PureComponent } from 'react';
import { Button, Col, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { LoadingMovilGo } from '../../shared/loadingMovilGo';
import pse from '../../assets/pagos/pse.png'
import { PseScreen } from '../../shared/pay/component/pse';
import card from '../../assets/pagos/card.png'
import { ButtonImagePay } from '../../shared/buttons';
import { PayCardScreen } from '../../shared/pay/component/card';
import { clientSession, idClienteFinal } from '../../utilities/odoo-config';
import { Desencrypt } from '../../utilities/cipher';
import Header from '../../shared/header/header';
import { jsPdfGenerator } from '../../shared/pdfTransaction';
import { FormatMoney } from '../../utilities/formats';
import { payMenRefacil } from '../../services/pay.service';
export class PagosExternosScreen extends PureComponent {
    constructor(props) {
        super(props);
        /* this.recargas = this.props.recargas; */
        this.state = {
            buttonsPay: [],
            pse: false,
            card: false,
            loading: false,
            userlogin: {},
            product: {},
            producto: '',
            resolucion: '',
            fechaSorteo: '',
            precio: '',
            identificacion: '',
            fullNameUser: '',
            numeroBoleta: '',
            phone: '',
            email: '',
            operadorEfectivo: '',
            partner_id: '',
            product_id: '',
            nombre_cliente: '',

        }
    }
    componentDidMount = () => {
        console.log("this.props de pagos jeje ", this.props)
        this.loadData()
    }
    loadData = () => {
        let url = window.location.search.split("&&");
        console.log("url del componente de pagos", url)

        // el url[0] es product_id
        // el url[1] es clienteexterno_user_id
        // el url[2] es numeroBoleta
        // el url[3] es valorPagar
        // el url[4] es partner_id
        // el url[5] es nombre_cliente
        // el url[6] es producto
        // el url[7] es resolucion
        // el url[8] es fecha sorteo
        // el url[9] es precio
        // el url[10] es cedula
        // el url[11] es telefono
        // el url[12] es correo
        // el url[13] es metodo de pago
        // el url[14] Nombre completo

        const product_id = url[0]
        const clienteexterno_user_id = url[1]
        const numeroBoleta = url[2]
        const valorPagar = url[3]
        const partner_id = url[4]
        const nombre_cliente = url[5]
        const producto = url[6]
        const resolucion = url[7]
        const fechaSorteo = url[8]
        const precio = url[9]
        const dniNumber = url[10]
        const phone = url[11]
        const email = url[12]
        const operadorEfectivo = url[13]
        const fullNameUser = url[14]

        let strNombreCliente = JSON.stringify(nombre_cliente).replace(/%20/g, ' ');
        strNombreCliente = strNombreCliente.replace(/\"/, '');
        strNombreCliente = strNombreCliente.replace(/\"/, '');

        let strProducto = JSON.stringify(producto).replace(/%20/g, ' ');
        strProducto = strProducto.replace(/\"/, '');
        strProducto = strProducto.replace(/\"/, '');

        let strProductId = JSON.stringify(product_id).replace('?', '');
        strProductId = strProductId.replace('"', '');

        console.log("log del strNombreCliente", strNombreCliente)
        console.log("log del strProducto", strProducto)
        console.log("log del strProductId", strProductId)
        const product = {
            product_id: parseInt(strProductId),
            clienteexterno_user_id: parseInt(clienteexterno_user_id),
            atributes: {
                numeroBoleta: "" + numeroBoleta,
                valorPagar: parseInt(valorPagar),
                partner_id: partner_id,
                //vendedor_externo_id: clienteexterno_user_id[1],//este user id no se puede enviar
                nombre_cliente: strNombreCliente
            }
        }
        console.log("el producto de salida", product);
        console.log("user de logeo", { user: clientSession.user, password: clientSession.password });


        url.forEach((item, index) => {
            console.log(`url[${index}]`, item);
        });

        this.setState({
            product: product,
            producto: strProducto,
            resolucion: resolucion,
            fechaSorteo: fechaSorteo,
            partner_id: partner_id,
            precio: parseInt(precio),
            fullNameUser: fullNameUser,
            userlogin: {
                user: clientSession.user,
                password: clientSession.password
            },
            numeroBoleta: numeroBoleta,
            identificacion: dniNumber,
            phone: phone,
            email: email,
            operadorEfectivo: operadorEfectivo,
            product_id: strProductId,
            nombre_cliente: strNombreCliente

        })

        this.loadPay()
    }
    loadPay = () => {
        let buttons = []
        for (let k = 0; k < imageOptions.length; k = k + 2) {
            buttons.push(
                <div key={'key' + k} style={styles.containerButton}>
                    <ButtonImagePay onClick={() => this.pressImage(imageOptions[k].name)} image={imageOptions[k].source}></ButtonImagePay>
                    {k + 1 < imageOptions.length && <ButtonImagePay onClick={() => this.pressImage(imageOptions[k + 1].name)} image={imageOptions[k + 1].source}></ButtonImagePay>}
                </div>


            )
        }
        this.setState({ buttonsPay: buttons })
    }

    pressImage = (routePay) => {
        console.log("imagen presionada", routePay)
        if (this.state.operadorEfectivo == 'refacil' && routePay != 'card') {
            console.log("es ", this.state.operadorEfectivo);
            this.setState({ loading: true });
            let data = []
            data = {
                "product": {
                    "atributes": {
                        "numeroBoleta": this.state.numeroBoleta,
                        "partner_id": parseInt(this.state.partner_id),
                        "nombre_cliente": this.state.nombre_cliente,
                        "valorPagar": this.state.precio
                    },
                    "clienteexterno_user_id": parseInt(idClienteFinal),
                    "product_id": parseInt(this.state.product_id)
                },
                "phone": this.state.phone,
                "emailAddress": this.state.email,
                "fullName": this.state.fullNameUser,
                "dniNumber": this.state.identificacion
            }

            console.log('Datos envio refacil', data);

            payMenRefacil(data, this.state.userlogin, async (flag, res) => {
                if (flag) {
                    console.log('Respuesta refacil:', res);

                    const urlPago = res && res.url_pago;

                    if (urlPago) {
                        this.setState({ loading: false });
                        // Redirigir automáticamente al navegador a la URL de pago
                        //window.location.href = urlPago;
                    } else {
                        this.setState({ loading: false });
                        console.error('No se encontró la URL de pago en la respuesta.');
                    }
                } else {
                    this.setState({ loading: false });
                    console.error('Error en el pago refacil', res);
                }
            });




        } else {
            this.setState({ [routePay]: true })
        }

    }
    navigateNext = async (flag, response) => {
        console.log("comprar navigateNext flag", flag)
        console.log("comprar navigateNext response", response)
        this.setState({ numberTicket: '', selectedPrice: '', })
        if (!flag && response) {

            let data = []
            data = [
                "Factura No: " + response.valida.id,
                "Fecha:" + response.valida.fecha,
                "No.Aprobacion:" + response.valida.numero_aprobacion,
                "Producto: " + this.state.producto,
                "Resolucion: " + this.state.resolucion,
                "Fecha del sorteo: " + this.state.fechaSorteo,
                "Número de boleta: " + this.state.numberTicket,
                "Cliente: " + this.state.userTicket,
                "Valor: " + FormatMoney(this.state.product.atributes.valorPagar)
            ]
            if (this.state.precio > this.state.product.atributes.valorPagar) {
                data = [
                    "Factura No: " + response.valida.id,
                    "Fecha:" + response.valida.fecha,
                    "No.Aprobacion:" + response.valida.numero_aprobacion,
                    "Producto: Abono " + this.state.producto,
                    "Resolucion: " + this.state.resolucion,
                    "Fecha del sorteo: " + this.state.fechaSorteo,
                    "Número de boleta: " + this.state.product.atributes.numeroBoleta,
                    "Cliente: " + this.state.product.atributes.nombre_cliente,
                    "Valor total: " + FormatMoney(this.state.precio),
                    "Valor abonado: " + FormatMoney(parseInt(this.state.product.atributes.valorPagar)),
                    "Valor por pagar: " + FormatMoney(this.state.precio - this.state.product.atributes.valorPagar),
                ]

            }
            jsPdfGenerator(data)
            //this.setState({ modalNotification: true, loading: false, opacity: 1, userTicket: '', viewTicket: false, selectedPrice: '', numberTicket: '', styleTicket: { borderWidth: 1 }, styleUser: { borderWidth: 1 }, identification: '' })

        } else {
            this.props.navigate('/')
        }
    }

    render() {
        return (
            <div class="col justify-content-center" style={{ alignSelf: 'center', width: '100%', height: '100%' }}>
                <div style={{ position: 'absolute', index: 1, display: 'inlineBlock', marginTop: 20, marginBottom: '10%' }}>
                    <Header deslogear={this.state.deslogear} newlogeo={(flag) => this.setState({ deslogear: flag })} />
                </div>
                <div style={{ height: 200, marginTop: '1%' }}>


                </div>
                <Col md={12} xs={12} sm={12} lg={12} >
                    {this.state.buttonsPay}
                </Col>
                <div style={{ height: 200, marginTop: '1%' }}>


                </div>
                {this.state.loading && <LoadingMovilGo />}
                {this.state.pse && <PseScreen
                    userLogin={this.state.userlogin}
                    visible={this.state.pse}
                    product={this.state.product}
                    closeModal={(flag, data, flag2) => {
                        if (flag2) {
                            /* this.props.navigate('/') */
                        }
                        this.setState({ pse: flag });

                    }}
                                         /* navigation={this.props.navigation} */ />}
                {this.state.card && <PayCardScreen
                    userLogin={this.state.userlogin}
                    visible={this.state.card}
                    product={this.state.product}
                    closeModal={(flag, data) => {
                        this.setState({ card: flag })

                        if (data) {
                            this.navigateNext(flag, data)
                                                            /* this.props.navigate('/') */;
                            /* window.location.replace('http://45.79.43.96:8099/Rifas/') */
                            window.close();
                        }

                    }} />}

            </div>

        )
    }
}

const imageOptions = [
    {
        name: 'pse',
        source: pse
    },
    {
        name: 'card',
        source: card
    }/*,
    {
      name:'efecty',
      source: efecty 
    },
    {
      name:'nequi',
      source: nequi 
    },
    {
      name:'daviplata',
      source: daviplata 
    } ,
    {
      name:"bitcoin",
      source: bitcoin 
    } */
]


const styles = {
    containerButton: {
        width: '50%',
        marginVertical: '1%',
        flexDirection: "column",
        top: '10%',
        left: 0,
        bottom: 0,
        right: 0,
        margin: 'auto'
    },
};